<template>
    <div class="table-widget" :style="cssProps">
        <template v-for="displayColumn in columns" :key="displayColumn.key">
            <div class="header even cell">
                {{ displayColumn.label }}
            </div>
        </template>
        <template v-for="displayRow in displayData">
            <template v-for="displayCell in displayRow.cells" :key="displayCell.id">
                <div class="cell" :class="displayRow.class">
                    <slot :name="displayCell.columnKey" v-bind:cell="displayCell" v-bind:row="displayRow" v-bind:item="displayRow.item">
                        {{displayCell.value}}
                    </slot>
                </div>
            </template>
        </template>
    </div>
</template>

<style lang="scss">
    .table-widget {
        display: grid;        
        grid-template-columns: var(--grid-template-columns);
        border: 1px solid lightgray;  
        font-size: 18px;             
        > .cell {
            display: grid;
            align-content: center;
            padding: 4px;
            &.header {
                font-weight: bold;  
            }
            &.even {
                background: #f7f7f7;
            }
            &.odd {
                background: #fafafa;
            }
        }
    }
</style>


<script>

    import { listenAbfrage } from "@/services";
    import { isPlainObject, isString } from 'lodash';

    export default { 
        props: {
            columns: {
                type: Array,
                required: true,
                validator: function (value) {
                    if (!Array.isArray) 
                        return false;                    
                    return true;
                }
            },
            rows: {
                type: Array,
                required: true
            }
        },
        computed: {
            cssProps() { 
                // console.log(this.columns) 
                let gridTemplateColumns = this.columns.map(f => f.columnWidth || "auto").join(' ')
                // console.log(gridTemplateColumns) 
                return {
                    '--grid-template-columns': gridTemplateColumns
                }
            },
            displayData() {
                return this.rows.map((row, index) => {
                    let displayRow = {
                        id: row.id,
                        class: {
                            odd:  (index % 2 == 0),
                            even: (index % 2 == 1),
                        },
                        cells: this.columns.map(column => {
                            let value = column.formatter ? column.formatter(row[column.key]) : row[column.key];
                            return {
                                id: row.id + "." + column.key,
                                columnKey: column.key,
                                value
                            }
                        }),
                        item: row
                    }
                    return displayRow;
                });

            }
        }
    }

</script>