<template>
    <div class="overlay-widget-v2" v-if="visible">
        <div class="container">
            <div class="x" @click.stop="close">&times;</div>
            <div class="title">
                {{title}}
            </div>
            <div class="content">
                <slot name="default" />
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .overlay-widget-v2 {
        position: fixed;
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
        display: grid;
        align-content: space-around;
        justify-content: space-around;
        cursor: default;
        text-align: left;
        > .container {
            display: grid;
            grid-template-rows: auto 1fr;
            font-size: 18px;
            position: relative;
            box-shadow: 7px 7px 18px 3px rgba(0, 0, 0, 0.75);
            background-color: white;
            border: 1px solid lightgray;
            border-radius: 10px;
            overflow: hidden;
            min-width: 60vw;
            min-height: 20vh;
            max-width: min(80vw, 1200px);
            max-height: 80vh;
            > .x {
                position: absolute;
                right: 8px;
                top: 2px;
                font-size: 24px;
                cursor: pointer;
            }
            > div.title {
                background: lightblue;
                color: black;
                display: grid;
                padding: 4px 24px;
                border-bottom: 1px solid lightgray;
                font-weight: bold;
            }
            > div.content {
                display: grid;
                align-items: center;
                padding: 4px 24px;
            }
        }
    }
</style>

<script>
    export default {
        name: 'OverlayWidget',
        props: {
            title: {
                type: String
            }
        },
        data: function() {
            return {
                visible: false,
                _resolve: null
            }
        },
        methods: {
            show() {
                this.visible = true;
                this.$emit("show");
            },
            close() {
                this.visible = false;
                this.$emit("close");
                if (this._resolve) 
                    this._resolve();
            },
            async showModal() {
                return new Promise((resolve, reject) => {
                    this.show();
                    this._resolve = resolve;
                });
            }
        }
    };
</script>