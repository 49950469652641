<template>
  <OverlayWidget class="new-list-modal" ref="overlay" :title="$attrs.title">
    <div class="formelement">
      <h3>Eingabedatei (<a href="resources/VerkaufBeispielEingabedateiV2.xlsx">Beispiel</a>)</h3>
      <input type="file" @change="fileSelected" />
      <div class="stats">
        Listenname: {{listName}}
        <br />
        Anzahl Einträge: {{fileStats.anzahlIDs.toFixed(0)}}
        <br />
        Summe Produkte: {{fileStats.summeProdukte.toFixed(0)}}
        <br />
        Summe VK: {{fileStats.summeVk.toFixed(2)}}
        <br />
        Summe EK: {{fileStats.summeEk.toFixed(2)}}
        <br />
      </div>
    </div>
    <div class="buttonrow">
      <button :disabled="!canOk" @click="doUpload">Hochladen</button>
    </div>
  </OverlayWidget>
</template>

<style lang="scss">
.new-list-modal {
  font-size: 20px;
  div.formelement {
    border: 1px solid lightgray;
    padding: 4px;
    div.stats {
      margin-top: 4px;
      border-top: 1px solid lightgray;
    }
  }
  div.buttonrow {
      text-align: right;
    button {
      &:not(:disabled) {
        border: 1px solid blue;
      }
      padding: 4px;
    }
  }
}
</style>

<script>
  
  import OverlayWidget from "@/components/widgets/overlay/overlay-widget-v2.vue"; 

  import { 
    get,
    size, 
    sum, 
    sumBy 
  } from "lodash";
    
  import { 
    read,
    utils
  } from "xlsx";

  import { neueListe } from "@/services";

const parseFile = file => {
  return new Promise((resolve, reject) => {
    try {
      let reader = new FileReader();
      reader.onload = function(e) {
        let data = new Uint8Array(e.target.result);
        let workbook = read(data, { type: "array" });
        let worksheet = workbook.Sheets[workbook.SheetNames[0]];
        let sheetData = utils.sheet_to_json(worksheet, {
          blankrows: false,
          raw: true
        });
        let parsedData = sheetData.map(s => ({
          id: String(s.EAN),
          vk: Number.parseFloat(s.VK),
          ek: Number.parseFloat(s.EK),
          menge: Number.parseInt(s.Menge),
          zustand: Number.parseInt(s.Zustand)
        }));
        resolve(parsedData);
      };
      reader.readAsArrayBuffer(file);
    } catch (e) {
      reject(e);
    }
  });
};

export default {
  components: {
    OverlayWidget
  },
  data() {
    return {
      fileData: null,
      listName: null
    };
  },
  computed: {
    fileStats() {
      return {   
        anzahlIDs: size(this.fileData),
        summeProdukte: sumBy(this.fileData, "menge"),
        summeVk: sumBy(this.fileData, f => f.menge * f.vk),
        summeEk: sumBy(this.fileData, f => f.menge * f.ek)
      };
    },
    canOk() {
      return (
        this.fileStats &&
        this.fileStats.anzahlIDs > 0 &&
        Number.isFinite(this.fileStats.summeProdukte) &&
        Number.isFinite(this.fileStats.summeVk) &&
        Number.isFinite(this.fileStats.summeEk)
      );
    }
  },
  methods: {
    async showModal() {      
      return this.$refs.overlay
        .showModal()
        .finally(()=>{
          this.fileData = null;
          this.listName = null;
        });
    },
    async fileSelected(e) {
      // Extract File
      let firstFile = get(e, "target.files[0]");
      if (!firstFile) this.$refs.overlay.close();
      // Parse File
      this.listName = firstFile.name.split('.').slice(0, -1).join('.');
      this.fileData = await parseFile(firstFile).catch(e => alert(e));
    },
    async doUpload() {
      if (!this.canOk) return;
      let cmd = {
        name: this.listName,
        items: this.fileData.map(f =>({
          ean: f.id,
          artikelNummer: f.id + f.zustand,
          vk: f.vk,
          ek: f.ek,
          menge: f.menge,
          zustand: f.zustand
        }))
      };
      await neueListe(cmd)
        .then(() => {
          alert("Liste übertragen");
          this.$refs.overlay.close();
        })
        .catch((e) => {
          alert("Fehler: " + e); 
        })
    }
  }
};
</script>
