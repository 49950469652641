import axios from 'axios';

export const axiosClient = axios.create();

export const listenAbfrage = () => {
    return axiosClient
        .get(`api/v1/liste`, { params: { } })
        .then(result => {
            if (result.status == 200)
                return result.data;
            else
                throw new Error(result.statusText);
        });
};

export const neueListe = (cmd) => {
    return axiosClient
        .put(`api/v1/liste`, cmd)
        .then(result => {
            if (result.status == 200)
                return result.data;
            else
                throw new Error(result.statusText);
        });
};

export const loescheListe = (id) => {
    return axiosClient
        .delete(`api/v1/liste/${id}`)
        .then(result => {
            if (result.status == 200)
                return result.data;
            else
                throw new Error(result.statusText);
        });
};

export const ladeListe = (id, type, jobname) => {
    return new Promise((resolve, reject) => {

        let filename = "";
        switch (type) {
            case "ok":
                filename = jobname + ".json";
                break;
            case "error":
                filename = jobname + ".error.json";
                break;
            case "lieferschein":
                filename = jobname + ".pdf";
                break;
            case "pixilog":
                filename = jobname + ".pixilog.json";
                break;                
            default:
                reject();
                return;
        }

        // https://stackoverflow.com/a/53230807/1501375
        axiosClient({
            url: `api/v1/liste/${id}/${type}`,
            method: 'GET',
            responseType: 'blob', 
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); 
            document.body.appendChild(link);
            link.click();
        });
        
        resolve();
    });
};

export const sendeListe = (id, type) => {
    return axiosClient
        .post(`api/v1/liste/sendmail/${id}/${type}`)
        .then(result => {
            if (result.status == 200)
                return result.data;
            else
                throw new Error(result.statusText);
        });
};

export const sendeLieferantenBestellung = (id, force = false) => {
    return axiosClient
        .put(`api/v1/liste/sendSupplierOrder/${id}/${force}`, {})
        .then(result => {
            if (result.status == 200)
                return result.data;
            else
                throw new Error(result.statusText);
        });
};