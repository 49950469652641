<template>
    <div class="base-view-mk-header">
        <div class="left">
            <img src="/logo.png">
        </div>
        <div class="center">
            Verkaufslisten           
        </div>
        <div class="right">
            
        </div> 
    </div>
</template>

<style lang="scss"> 

    @import "@/styles/_responsive.scss";

    $border-radius: 10px;

    .base-view-mk-header { 
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr 10fr 1fr;
        padding: 10px;
        margin: -10px;
        background: #f7f7f7;
        > .left {
            display: grid;
            justify-content: start;
            align-items: center;
            > img {
                height: 60px;
            }                
        }
        > .center {
            display: grid;
            grid-gap: 8px;
            justify-content: center;
            align-items: center;
            font-size: 32px;
            font-weight: bold;           
        }
        > .right {
            display: grid;
            align-items: center;
            justify-content: end;
        }
        @include responsive(md) { 
            grid-template-columns: auto;
        }
    }
</style>

<script>

    export default {
        props: {
            bereichsTitel: {
                type: String,
                default: ""
            }
        }
    };

</script>