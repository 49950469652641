import { createRouter, createWebHashHistory } from "vue-router";

import HomeView from "@/components/views/home/home-view.vue";

const routes = [    
    {
        path: '/home',
        component: HomeView
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/home'
    }
]

export const router = createRouter({
    history: createWebHashHistory(),
    routes
});