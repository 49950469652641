<template>
    <BaseView bereichsTitel="System" class="home-view">
        <div class="toolbar">
            <div></div>
            <div class="button" @click="uploadListe">
                <icon-widget name="Upload" /> 
                <span>Neue Liste hochladen</span>
            </div>
            <NewModal title="Neue Liste hochladen" ref="newListModal" />
        </div>
        <TableWidget :rows="listItems" :columns="tableColumns">
            <template v-slot:status="{ item }">
                <Popper :content="item.statusText" :hover="true">
                    <div class="item-status">
                        {{item.status}}         
                    </div>
                </Popper>
            </template>
            <template v-slot:aktionen="{ item }">
                <div class="item-toolbar">
                    <IconWidget name="Download" tooltip="Shopdatei herunterladen" 
                        @click="downloadItem(item.id, 'ok')" v-if="item.status == 'DONE'" /> 
                    <IconWidget name="Alert" tooltip="Fehlerliste herunterladen" 
                        @click="downloadItem(item.id, 'error')" v-if="item.status == 'DONE'" />
                    <IconWidget name="TruckDeliveryOutline" tooltip="Lieferschein herunterladen" 
                        @click="downloadItem(item.id, 'lieferschein')" v-if="item.status == 'DONE'" />
                    <IconWidget name="EmailArrowRightOutline" tooltip="Lieferschein per Email verschicken" 
                        @click="sendItem(item.id, 'lieferschein')" v-if="item.status == 'DONE'" />  
                    <IconWidget name="ArchiveArrowUpOutline" tooltip="Lieferantenbestellung Log herunterladen" :class="{red: (item.pixiState == 'ERR'), green: (item.pixiState == 'OK')}" 
                        @click="downloadItem(item.id, 'pixilog')" v-if="item.status == 'DONE' && item.pixiState != null" />                          
                    <IconWidget name="ArchiveArrowUpOutline" tooltip="Lieferantenbestellung VAH/Jager erzeugen" 
                        @click="uploadSupplierOrder(item.id)" v-if="item.status == 'DONE' && item.pixiState == null" />  
                    <IconWidget name="DeleteOutline" tooltip="Liste löschen" 
                        @click="deleteItem(item.id)" v-if="item.status == 'DONE'" /> 
                </div>
            </template>
        </TableWidget>
    </BaseView>
</template>

<style lang="scss">

    @import "@/styles/_responsive.scss";

    .home-view {
        > .main {
            padding: 10px 0;
            grid-gap: 4px;
            div.toolbar {
                display: grid;
                grid-template-columns: 1fr auto;
                grid-gap: 4px;
                justify-items: end;
                font-weight: bold;
                font-size: 18px;
                div.button {                    
                    display: grid;
                    grid-auto-flow: column;
                    grid-template-columns: auto 1fr;
                    align-items: center;
                    cursor: pointer;
                    border: 1px solid lightgray;
                    padding: 4px;
                    @include hoverhelper() {
                        &:hover {
                            background: rgba(#000000, 0.1);
                        }  
                    }
                }
            }
            .table-widget {
                .item-toolbar {
                    display: grid;
                    grid-gap: 4px;
                    grid-auto-flow: column;
                    .icon-widget {
                        &.green {
                            color: green;
                        }
                        &.red {
                            color: red;
                        }
                    }
                }
            }
        }
    }
</style>

<script>

    import BaseView from "../base-view.vue";
    import IconWidget from "@/components/widgets/icons/icon-widget.vue";
    import TableWidget from "@/components/widgets/table/table-widget.vue";
    import NewModal from "./newlist/new-list-modal.vue"

    import { listenAbfrage, ladeListe, loescheListe, sendeListe, sendeLieferantenBestellung } from "@/services";
    import moment from "moment";
    import { orderBy } from "lodash";

    const tableColumns = [
        {key:'createdAt', label:'Datum', formatter: (value) => moment(value).format("DD.MM.YYYY HH:mm:ss")}, 
        {key:'name', label: 'Name', columnWidth: "1fr"}, 
        {key:'status', label:'Status'}, 
        {key:'progress', label:'OK Rate'},
        {key:'aktionen', label:'Aktionen'}
    ];

    export default { 
        components: {
            BaseView,
            IconWidget,
            TableWidget,
            NewModal
        },
        data() {
            return {
                listItems: [],
                tableColumns
            }
        },
        methods: {
            async refreshItems() {
                this.listItems = await listenAbfrage()
                    .then(x => {
                        let items = orderBy(x.items, "createdAt", "desc");
                        items.forEach(y => y.progress = (100.0 * (y.numOk / y.numTotal)).toFixed(1) + " %")
                        return items;
                    })
                    .catch(e => alert(e));
            },
            async deleteItem(itemId) {
                return loescheListe(itemId)
                    .catch(e => alert(e))
                    .then(this.refreshItems);
            },
            async sendItem(itemId, type) {
                return sendeListe(itemId, type)
                    .catch(e => alert(e));
            },
            async downloadItem(itemId, type) {
                let jobname = this.listItems?.find(x => x.id == itemId)?.name;
                return ladeListe(itemId, type, (jobname || id))
                    .catch(e => alert(e));
            },
            async uploadListe() {
                return this.$refs.newListModal
                    .showModal()
                    .then(this.refreshItems);
            },
            async uploadSupplierOrder(itemId) {
                return sendeLieferantenBestellung(itemId, true)
                    .catch(e => alert(e));
            }
        },
        mounted() {
            this.refreshItems();
            setInterval(this.refreshItems, 30000);           
        }
    }

</script>