<template>
    <div class="base-view">
        <div class="header">
            <Header :bereichsTitel="$attrs['bereichsTitel']" />
        </div>
        <div class="main">
            <slot />
        </div>
        <div class="footer">
            <slot name="footer" />
        </div>
    </div>
</template>

<style lang="scss">
    .base-view {
        display: grid;
        grid-auto-flow: row;
        grid-template-rows: auto 1fr auto;
        padding: 10px;
        grid-gap: 10px;
        > .header {
            display: grid;
            grid-row: 1;
        }
        > .main {
            display: grid;
            grid-row: 2;
            overflow: auto;
            align-content: start;
        }
        > .footer {
            display: grid;
            grid-row: 3;
        }
    }
</style>

<script>

    import Header from "./base-view-mk-header.vue";

    export default {
        components: {
            Header
        }
    };

</script>
