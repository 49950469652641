<template>
    <div class="icon-widget" 
        :style="cssProps" :class="{clickable}" 
        :tabindex="clickable ? 0 : undefined">
        <Popper :content="tooltipProps" :hover="true">
            <component :is="selectedIcon.component" title />
        </Popper>
    </div>
</template>

<style lang="scss">

    @import "@/styles/_responsive.scss";

    .icon-widget {

        display: grid;
        justify-content: center;
        align-content: center;
        width: var(--width);
        height: var(--height);
        
        .material-design-icon {
            overflow: hidden;
            display: grid;
            width: var(--width);
            height: var(--height);
            > .material-design-icon__svg {
                width: 100%;
                height: 100%;
            }
        }  

        &.clickable {
            cursor: pointer;
            .material-design-icon { 
                border-radius: 0%;
                background: rgba(0,0,0,0.0);
                transform: none;
                transition: 0.1s;
            }
            @include hoverhelper() {
                .material-design-icon {
                    &:hover {
                        background: rgba(0,0,0,0.1);
                        border-radius: 50%;
                        transform: scale(1.2);
                    } 
                }
            }
        }

    }

</style>

<script>
    
    import { find } from 'lodash';
    import * as ICONS from "./icons";
    const allExportedIcons = Object.keys(ICONS).map(key => ICONS[key]);

    export default {
        props: {
            name: {
                type: String,
                default: ICONS.ICON_Cogs.name
            },
            width: {
                type: Number,
                default: 24
            },
            height: {
                type: Number,
                default: 24
            },
            tooltip: {
                type: String,
                default: undefined
            }
        },        
        computed: {
            clickable() {
                return (this.$attrs['onClick'] != null);
            },
            selectedIcon() {
                let icon = find(allExportedIcons, i => i.name == this.name)
                if (!icon) 
                    throw(`Icon ${this.name} not found`)
                else
                    return icon;
            },
            cssProps() { 
                return {
                    '--width':  (this.width  + 'px'),
                    '--height': (this.height + 'px')
                }
            },
            tooltipProps() {
                let content = (this.tooltip !== undefined) ? this.tooltip : this.selectedIcon.tooltip;
                return content;
            }       
        }
    };
</script>