import {createApp} from 'vue'
import {router} from "./router";

import "@/styles/_popper.css";
import Popper from "vue3-popper";

import { init as initAuth } from '@/services/auth';
await initAuth();

import AppShell from "@/components/shells/app-shell.vue";

const app = createApp(AppShell);
app.component("Popper", Popper);
app.use(router);
app.mount('#app');