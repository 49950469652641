import { axiosClient}  from "./index";
import Keycloak from "keycloak-js";

export const init = async () => {

    /**
    * Keycloak Config laden
    */
    const keycloakConfig = await axiosClient
        .get("/api/public/keycloak")
        .then(response => response.data);

    /** Keycloak Client initialisieren
    * @see https://www.keycloak.org/docs/latest/securing_apps/index.html#javascript-adapter-reference 
    */
    const keycloak = new Keycloak({
        url: keycloakConfig.serverUrl,
        realm: keycloakConfig.realm,
        clientId: "verkauf"
    });

    /**
     * Login mit evtl. bestehdendem Cookie durchführen
     */
    await keycloak.init({
        onLoad: 'login-required',
        responseMode: 'query'
    }).then (loggedIn => {
        console.debug(`Keycloak User LoggedIn ${loggedIn}`);
        axiosClient.defaults.headers['Authorization'] = `Bearer ${keycloak.token}`;
    }).catch(error => {
        console.error(error);
        axiosClient.defaults.headers['Authorization'] = null;
    });
            
    /**
     * AutoRefresh des Token
     */
    setInterval(
        () => {
            keycloak.updateToken(70)
                .then(refreshed => {
                    console.debug(`Keycloak Token ${refreshed ? '' : 'not '}refreshed`);
                    axiosClient.defaults.headers['Authorization'] = `Bearer ${keycloak.token}`;
                })
                .catch(error => {
                    console.error(error);
                    axiosClient.defaults.headers['Authorization'] = null;
                });   
        }, 
        15000
    );

}