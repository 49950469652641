import AccountMultipleIcon from "vue-material-design-icons/AccountMultiple.vue";
export const ICON_AccountMultiple = {
    name: 'AccountMultiple',
    component: AccountMultipleIcon,
    tooltip: null
}

import AccountAlertIcon from "vue-material-design-icons/AccountAlert.vue";
export const ICON_AccountAlert = {
    name: 'AccountAlert',
    component: AccountAlertIcon,
    tooltip: null
}

import AlertIcon from "vue-material-design-icons/Alert.vue";
export const ICON_Alert = {
    name: 'Alert',
    component: AlertIcon,
    tooltip: null
}

import ArrowDownDropCircleIcon from "vue-material-design-icons/ArrowDownDropCircle.vue";
export const ICON_ArrowDownDropCircle = {
    name: 'ArrowDownDropCircle',
    component: ArrowDownDropCircleIcon,
    tooltip: null
}

import ArchiveArrowUpOutlineIcon from "vue-material-design-icons/ArchiveArrowUpOutline.vue";
export const ICON_ArchiveArrowUpOutline = {
    name: 'ArchiveArrowUpOutline',
    component: ArchiveArrowUpOutlineIcon,
    tooltip: null
}

import ArrowDownDropCircleOutlineIcon from "vue-material-design-icons/ArrowDownDropCircleOutline.vue";
export const ICON_ArrowDownDropCircleOutline = {
    name: 'ArrowDownDropCircleOutline',
    component: ArrowDownDropCircleOutlineIcon,
    tooltip: null
}

import ArrowLeftIcon from "vue-material-design-icons/ArrowLeft.vue";
export const ICON_ArrowLeft = {
    name: 'ArrowLeft',
    component: ArrowLeftIcon,
    tooltip: null
}

import ArrowRightIcon from "vue-material-design-icons/ArrowRight.vue";
export const ICON_ArrowRight = {
    name: 'ArrowRight',
    component: ArrowRightIcon,
    tooltip: null
}

import CogsIcon from "vue-material-design-icons/Cogs.vue";
export const ICON_Cogs = {
    name: 'Cogs',
    component: CogsIcon,
    tooltip: null
}

import CommentTextOutlineIcon from "vue-material-design-icons/CommentTextOutline.vue";
export const ICON_CommentTextOutline = {
    name: 'CommentTextOutline',
    component: CommentTextOutlineIcon,
    tooltip: null
}

import ContentSaveIcon from "vue-material-design-icons/ContentSave.vue";
export const ICON_ContentSave = {
    name: 'ContentSave',
    component: ContentSaveIcon,
    tooltip: null
}

import DeleteOutlineIcon from "vue-material-design-icons/DeleteOutline.vue";
export const ICON_DeleteOutline = {
    name: 'DeleteOutline',
    component: DeleteOutlineIcon,
    tooltip: null
}

import DownloadIcon from "vue-material-design-icons/Download.vue";
export const ICON_Download = {
    name: 'Download',
    component: DownloadIcon,
    tooltip: null
}

import EmailArrowRightOutline from "vue-material-design-icons/EmailArrowRightOutline.vue";
export const ICON_EmailArrowRightOutline = {
    name: 'EmailArrowRightOutline',
    component: EmailArrowRightOutline,
    tooltip: null
}

import EyeOutlineIcon from "vue-material-design-icons/EyeOutline.vue";
export const ICON_EyeOutline = {
    name: 'EyeOutline',
    component: EyeOutlineIcon,
    tooltip: null
}

import FilePdfOutlineIcon from "vue-material-design-icons/FilePdfBox.vue";
export const ICON_FilePdfOutline = {
    name: 'FilePdfOutline',
    component: FilePdfOutlineIcon,
    tooltip: null
}

import FilterIcon from "vue-material-design-icons/Filter.vue";
export const ICON_Filter = {
    name: 'Filter',
    component: FilterIcon,
    tooltip: null
}

import FolderPoundOutlineIcon from "vue-material-design-icons/FolderPoundOutline.vue";
export const ICON_FolderPoundOutline = {
    name: 'FolderPoundOutline',
    component: FolderPoundOutlineIcon,
    tooltip: null
}

import FormatListChecksIcon from "vue-material-design-icons/FormatListChecks.vue";
export const ICON_FormatListChecks = {
    name: 'FormatListChecks',
    component: FormatListChecksIcon,
    tooltip: null
}

import FormatTextIcon from "vue-material-design-icons/FormatText.vue";
export const ICON_FormatText = {
    name: 'FormatText',
    component: FormatTextIcon,
    tooltip: null
}

import InformationOutlineIcon from "vue-material-design-icons/InformationOutline.vue";
export const ICON_InformationOutline = {
    name: 'InformationOutline',
    component: InformationOutlineIcon,
    tooltip: null
}

import KeyboardBackspaceIcon from "vue-material-design-icons/KeyboardBackspace.vue";
export const ICON_KeyboardBackspace = {
    name: 'KeyboardBackspace',
    component: KeyboardBackspaceIcon,
    tooltip: null
}

import MagnifyIcon from "vue-material-design-icons/Magnify.vue";
export const ICON_Magnify = {
    name: 'Magnify',
    component: MagnifyIcon,
    tooltip: null
}


import OrderAlphabeticalAscendingIcon from "vue-material-design-icons/OrderAlphabeticalAscending.vue";
export const ICON_OrderAlphabeticalAscending = {
    name: 'OrderAlphabeticalAscending',
    component: OrderAlphabeticalAscendingIcon,
    tooltip: null
}

import OrderAlphabeticalDescendingIcon from "vue-material-design-icons/OrderAlphabeticalDescending.vue";
export const ICON_OrderAlphabeticalDescending = {
    name: 'OrderAlphabeticalDescending',
    component: OrderAlphabeticalDescendingIcon,
    tooltip: null
}

import PencilIcon from "vue-material-design-icons/Pencil.vue";
export const ICON_Pencil = {
    name: 'Pencil',
    component: PencilIcon,
    tooltip: null
}

import PlusIcon from "vue-material-design-icons/Plus.vue";
export const ICON_Plus = {
    name: 'Plus',
    component: PlusIcon,
    tooltip: null
}

import RefreshIcon from "vue-material-design-icons/Refresh.vue";
export const ICON_Refresh = {
    name: 'Refresh',
    component: RefreshIcon,
    tooltip: null
}

import SortAscendingIcon from "vue-material-design-icons/SortAscending.vue";
export const ICON_SortAscending = {
    name: 'SortAscending',
    component: SortAscendingIcon,
    tooltip: null
}

import SortDescendingIcon from "vue-material-design-icons/SortDescending.vue";
export const ICON_SortDescending = {
    name: 'SortDescending',
    component: SortDescendingIcon,
    tooltip: null
}

import SortIcon from "vue-material-design-icons/Sort.vue";
export const ICON_Sort = {
    name: 'Sort',
    component: SortIcon,
    tooltip: null
}

import TableRowPlusAfterIcon from "vue-material-design-icons/TableRowPlusAfter.vue";
export const ICON_TableRowPlusAfter = {
    name: 'TableRowPlusAfter',
    component: TableRowPlusAfterIcon,
    tooltip: null
}

import TextBoxSearchOutlineIcon from "vue-material-design-icons/TextBoxSearchOutline.vue";
export const ICON_TextBoxSearchOutline = {
    name: 'TextBoxSearchOutline',
    component: TextBoxSearchOutlineIcon,
    tooltip: null
}

import TruckDeliveryOutlineIcon from "vue-material-design-icons/TruckDeliveryOutline.vue";
export const ICON_TruckDeliveryOutline = {
    name: 'TruckDeliveryOutline',
    component: TruckDeliveryOutlineIcon,
    tooltip: null
}

import UploadIcon from "vue-material-design-icons/Upload.vue";
export const ICON_Upload = {
    name: 'Upload',
    component: UploadIcon,
    tooltip: null
}

