<template>
    
        <main>
            <router-view></router-view>
        </main>
    
</template>

<style lang="scss">
    
    #app {
        height: 100vh;
        width: 100vw;
        display: grid;
        background: white;
        color: #17355E;
        font-size: 14px;
        > main {
            display: grid;
            min-width: 600px;
            overflow-y: auto;            
            overflow-x: hidden;
            -webkit-overflow-scrolling: touch;             
            background: white;
            position: relative;
        }
    }

    @media (min-width: 1000px) { 
        #app::before  {
            position: fixed;
            z-index: -1;
            background-color: lightgray;
            width: 100%;
            height: 127px;
            content: '';
            top: 0;
            left: 0;
        }
        #app {
            width: 1000px;
            height: calc(100vh - 40px);
            margin-top: 20px;
            margin-bottom: 20px;
            box-shadow: 7px 7px 18px 3px rgba(0,0,0,0.75);
            border-radius: 4px;
        }
    }  

</style>